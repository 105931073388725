<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedTimerActions"
        :default-value="properties.action"
        step-label="timer"
        :readonly="!canEdit"
        required
        @change="onActionChange($event)"
      />
    </v-col>
  
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        data-cy="timer-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        class="required-asterisk"
      />
    </v-col>
  
    <v-col cols="12" class="pb-3">
      <v-text-field
        v-if="properties.action === 'END'"
        :key="`${step.id}-startTime`"
        v-model="properties.fields.startTime"
        outlined
        dense
        data-cy="timer-startTime"
        :label="$lang.labels.startTime"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        class="required-asterisk"
      />
    </v-col>
  </v-row>
</template>
  
<script>
import { TIMER_ACTIONS } from './constants'
import { ActionSelect } from './components'
import StepMixin from './StepMixin'
  
export default {
  name: 'TimerStep',
  components: {
    ActionSelect
  },
  mixins: [StepMixin],
  computed: {
    formattedTimerActions() {
      return TIMER_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  methods: {
    onActionChange(val) {
      if (val === 'END') {
        if (!this.properties.fields) this.$set(this.properties, 'fields', {})
      }
      if (val === 'START') {
        if (this.properties.fields) this.$delete(this.properties, 'fields')
      }
      this.handleChange('action', val)
    }
  }
}
</script>
